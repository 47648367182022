import type { HomePageQueryResult } from "../../sanity.types";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";

export const PricingTable = ({
  details,
}: {
  details: HomePageQueryResult["pricingTable"];
}) => (
  <div className="pt-4">
    <h3 className="text-center font-bold text-green-800 text-lg uppercase tracking-wider">
      Price by Deer Size
    </h3>
    <Table className=" w-80">
      <TableHeader>
        <TableRow>
          <TableHead className="">Deer Size</TableHead>
          <TableHead className="text-right">Price</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {details?.map(({ deerSize, price }) => (
          <TableRow key={deerSize}>
            <TableCell className="font-medium">{deerSize}</TableCell>
            <TableCell className="text-right">{price}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);
